import {
  SiCss3,
  SiReact,
  SiTailwindcss,
  SiTypescript,
  SiAntdesign,
  SiPython,
  SiFigma,
} from "react-icons/si";
import { IconType } from "react-icons";

export type ProjectProps = {
  id: number;
  name: string;
  description: string;
  technologies: IconType[];
  techNames: string[];
  techLinks: string[];
  github: string;
  demo: string;
  image: string;
  available: boolean;
};

export const projects = [
  {
    id: 0,
    name: "Bouygues SA",
    description:
      "Développement d'une application interne en ReactJS et NodeJS pour la communauté RH du groupe Bouygues.",
    technologies: [SiTypescript, SiReact, SiCss3, SiPython],
    techNames: ["TypeScript", "React", "CSS", "Python"],
    techLinks: [
      "https://www.typescriptlang.org/",
      "https://reactjs.org/",
      "https://developer.mozilla.org/fr/docs/Web/CSS",
      "https://www.python.org/",
    ],
    demo: "https://www.bouygues.com/",
    github: "",
    image: "/projects/bysa.jpg",
    available: false,
  },
  {
    id: 1,
    name: "L'Oréal",
    description:
      "Création d'un éco-système d'applications internes en React TS pour faciliter la recherche et le développement de nouveaux produits cosmétiques.",
    technologies: [SiTypescript, SiReact, SiTailwindcss, SiAntdesign],
    techNames: ["TypeScript", "React", "Tailwind CSS", "Ant Design"],
    techLinks: [
      "https://www.typescriptlang.org/",
      "https://reactjs.org/",
      "https://tailwindcss.com/",
      "https://ant.design/",
    ],
    image: "/projects/loreal.jpg",
    demo: "https://www.bouygues.com/",
    github: "",
    available: false,
  },
  /*  {
    id: 2,
    name: "Agence RJS",
    description:
      "Création, développement, mise à jours et maintenance d'un pannel de site web pour des clients variés.",
    technologies: [SiTypescript, SiReact, SiTailwindcss, SiAntdesign, SiFigma],
    techNames: ["TypeScript", "React", "Tailwind CSS", "Ant Design", "Figma"],
    techLinks: [
      "https://www.typescriptlang.org/",
      "https://reactjs.org/",
      "https://tailwindcss.com/",
      "https://ant.design/",
      "https://www.figma.com/",
    ],
    image: "/projects/rjs.png",
    demo: "https://www.bouygues.com/",
    github: "",
    available: false,
  }, */
];
