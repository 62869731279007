import React from "react";
import "../animations/animate.css";
import AnimatedBody from "../animations/AnimatedBody";
import AnimatedTitle from "../animations/AnimatedTitle";

const About = () => {
  return (
    <section
      className="relative z-10 w-full items-center justify-center overflow-hidden bg-[#0E1016] bg-cover bg-center pt-16 pb-36 md:pt-20 md:pb-44 lg:pt-20 lg:pb-56"
      id="about"
    >
      <div className="mx-auto flex w-[90%] flex-col items-center justify-center lg:max-w-[1212.8px]">
        <AnimatedTitle
          text={"Besoin d'un développeur full stack ?"}
          className={
            "mb-10 text-left text-[40px] font-bold leading-[0.9em] tracking-tighter text-[#e4ded7] sm:text-[45px] md:mb-16 md:text-[60px] lg:text-[80px]"
          }
          wordSpace={"mr-[14px]"}
          charSpace={"mr-[0.001em]"}
        />

        <div className="mx-auto flex w-[100%] flex-col lg:max-w-[1200px] lg:flex-row lg:gap-20">
          <div className="mb-10 flex w-[100%] flex-col gap-4 text-[18px] font-medium  leading-relaxed tracking-wide text-[#e4ded7] md:mb-16 md:gap-6 md:text-[20px] md:leading-relaxed lg:mb-16  lg:max-w-[90%] lg:text-[24px] ">
            <AnimatedBody text="Chaque projet digital représente une opportunité cruciale pour votre entreprise dans un monde ou le web est en constante évolution. En tant que développeur Full Stack, mon objectif est simple : je créer des solutions numériques sur mesure pour répondre précisément à vos besoins." />

            <AnimatedBody
              delay={0.1}
              text="Avec plus de 4 années d'expérience, j'ai acquis une expertise approfondie dans le développement front-end et back-end."
            />

            <AnimatedBody
              delay={0.2}
              text="Mon objectif principal est de fournir des solutions de haute qualité qui répondent à vos exigences, à vos objectifs et à nos valeurs écologiques 🌱."
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
